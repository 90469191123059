<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataDetail.activity_category ? dataDetail.activity_category : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Sub-Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataDetail.activity_category_id ? dataDetail.sub_activity_category : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kegiatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataDetail.name ? dataDetail.name : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Keterangan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                id=""
                name=""
                class="uk-textarea"
                cols="40"
                rows="5"
                :value="dataDetail.description ? dataDetail.description : '-'"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Tipe Input
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataDetail.input_type ? dataDetail.input_type : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Terakhir Diubah
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataDetail.updated_at ? formatDate(dataDetail.updated_at) : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Diubah Oleh
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataDetail.updated_by ? dataDetail.updated_by : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="filter-container">
        <div class="uk-form-horizontal">
          <div uk-grid>
            <div
              v-for="(val, i) in dataDetail.work_time"
              :key="i"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl@s"
            >
              <label
                class="uk-form-label"
                for="form-horizontal-text"
              >Waktu Pengerjaan {{ i + 1 }}</label>
              <div class="uk-form-controls">
                <div
                  uk-grid
                  class="uk-grid-small"
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    <input
                      class="uk-input"
                      :value="val.time ? convSplit(val.time)[0] : '00:00'"
                      type="text"
                      disabled
                    >  
                  </div>
                  <div class="uk-width-1-5@s uk-text-center">
                    to
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    <input
                      class="uk-input"
                      :value="val.time ? convSplit(val.time)[1] : '00:00'"
                      type="text"
                      disabled
                    >  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </button>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-text-right">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="showDeleteConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/trash-white.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Hapus
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToEdit"
          >
            <img
              v-lazy="`${images}/icon/edit.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Ubah
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'

export default {
  components: {
  },
  props: {
    dataDetail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  methods: {
    ...mapMutations({
      setModalDelete: 'detailActivityCategory/SET_MODAL_DELETE'
    }),
    convSplit(str){
      return str.split('-')
    },
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    goToBack() {
      this.$router.push('/admin/detail-kegiatan')
    },
    goToEdit() {
      this.$router.push('/admin/detail-kegiatan/edit/'+ this.dataDetail.id)
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataDetail)
    }
  }
}
</script>
